import React, { useState, useEffect, useContext } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FaUser, FaHome, FaUserFriends, FaBars , FaArchive } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa6';
import { MdLogout } from 'react-icons/md';
import { RiArchiveStackFill } from "react-icons/ri";
import { MdOutlineViewCompact } from "react-icons/md";
import { MdAccountCircle } from "react-icons/md";
import image1 from '../images/1.PNG'
import image2 from '../images/2.PNG'
import image3 from '../images/Nwap.PNG'
import Coda from '../images/Coda.PNG'

import './Layout.css';
import './Navbar.css';
import './Sidebar.css';
import { UserContext } from '../App';
import axios from 'axios';

const Layout = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { role, setRole } = useContext(UserContext); 
  const navigate = useNavigate();

  // const toggleSidebar = () => {
  //   setIsOpen(!isOpen);
  // };

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth > 768) {
  //       setIsOpen(true);
  //     } else {
  //       setIsOpen(false);
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);

  //   handleResize(); 

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token'); 

      if (token) {
        await axios.post('/api/account/logout', {}, {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        });

        
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        
        setRole(null);

        
        navigate('/');
      }
    } catch (error) {
      console.error('Error logging out:', error);
      
    }
  };

  
  const getNavbarTitle = () => {
    switch (role) {
      case 'Admin':
        return 'Admin'; // Admin role
      case 'User':
        return 'User'; // User role
      case 'View':
        return 'View'; // View role
      default:
        return 'Unknown'; // Default case
    }
  };

  const name = localStorage.getItem('name')

  return (
    <div className="layout">
      <nav className="navbar">
        <div className="navbar-brand"></div>
        <div className="navbar-links">
          <Link to="/layout" className="navbar-admin">
            <FaUser className="iconrole" /> {name}
          </Link>
        </div>
      </nav>
      <div>
        <button className="toggle-button" >
          <FaBars />
        </button>
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
          <div className="sidebar-header">
          <img src={Coda} alt="Description of image" className="imagecoda" />
          </div>
          <ul>
          <li>
              <Link to="/layout/profile">
                <MdAccountCircle className="icon" /> الحساب الشخصي
              </Link>
            </li>
          {role === 'Admin' && (
            <li>
              <Link to="/layout/users">
                <FaUsers className="icon" /> المستخدمين
              </Link>
            </li>
          )}
            <li>
              <Link to="/layout/home">
                <FaHome className="icon" /> الرئيسية
              </Link>
            </li>
            <li>
              <Link to="/layout/names">
                <FaUserFriends className="icon" /> اسماء النواب
              </Link>
            </li>
            <li>
              <Link to="/layout/Archive">
                <FaArchive className="icon" />الارشيف
              </Link>
            </li>   
            <li>
              <Link to="/Viewer">
                <MdOutlineViewCompact className="icon" /> واجهة العرض
              </Link>
            </li>
            <li className='signout'>
             
              
              <Link to="/" onClick={handleLogout} style={{ textDecoration: 'none', color: 'inherit' }}>
                <MdLogout className="icon" /> تسجيل خروج
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="content">
      <img src={image3} alt="Description of image" className="image-layout" />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
